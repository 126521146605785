import * as React from "react";
import { Icon } from "@iconify/react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { env } from "../../../environment";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export interface EnquirySubmitModel {
    centreUserIdHash: string;
    enquiryType: number;
    recaptchaResult: string;
    parentFullName: string;
    email: string;
    contactNumber: string;
    bestTimeToCall: string;
    saveDetails: boolean;
    enquiryDetails: any;
    selectedVisitDays: any[];
    childrenDetails: ChildrenDetail[];
    additionalQuestions: any[];
    liveTourBookingSlot: string;
    token: string;
    includeRecommendCentresHtml: boolean;
    includeUpsellCentresHtml: boolean;
    centrePostcode: string;
    centreSuburbHashId: string;
}

export interface ChildrenDetail {
    name: string;
    birthday: string;
    careStartDate: string;
    selectedCareDays: number[];
    overnightCareRequired: boolean;
}

interface IEnquiryLiveTourReviewComponent {
    [key: string]: any;
}
export const EnquiryLiveTourReviewComponent = (
    props: IEnquiryLiveTourReviewComponent
) => {
    const { enquiryDetail, data, sendNewEventGa4SubmitSuccess, sendNewEventGa4SubmitFail, centreBrandHashId } = props;
    // console.log('props=', props);

    const { profile, liveTourAvailData } = enquiryDetail;
    const [loader, setLoader] = React.useState(false);
    const [dayName, setDayName] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
 
    const { executeRecaptcha } = useGoogleReCaptcha();

 
    React.useEffect(() => {
        let daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        let date = new Date(enquiryDetail?.liveTourAvailData?.slot);
        let day = date.getDay();
        setDayName(daysOfWeek[day]);
    }, []);
    const getAllIdsFromCookies = (): string[] => {
        const cookies = document.cookie.split('; ');
        const ids: string[] = [];

        cookies.forEach(cookie => {
            if (cookie.startsWith('brandedHash_')) {
                const id = cookie.split('=')[0].split('_')[1]; // Extract ID from cookie name
                ids.push(id);
            }
        });

        return ids;
    };

    const hasVisitedBrandedHub = (getAllIdsFromCookies().includes('g8') && props.centreGroupId === 1) || getAllIdsFromCookies().includes(props.centreBrandHashId)

    /* 
      --------------------
        Handlers function
      ---------------------
    */
    const onSubmit = async () => {
        //sendNewEventGa4Review()
        // api call
        if (!executeRecaptcha) {
            setErrorMessage("reCAPTCHA not available yet");
            props.sendEventGa4('review', 'submit-fail')
            return;
        }
    
        setLoader(true);
        const token = await executeRecaptcha("api_call");

        let payload: EnquirySubmitModel = enrichForEnquirySave(token);
     
        let headers = {
            "Content-Type": "application/json",
        };
        let url = data.enquiryRequestUrl;
        const json = JSON.stringify(payload);
        axios
            .post(url, json, { headers: headers })
            .then((response: any) => {
                if (response.data.isSuccess) {
                    sendNewEventGa4SubmitSuccess()
                    let enquiryId = 0;
                    if (response?.data?.enquiryIds != null) {
                        var key = Object.keys(response?.data?.enquiryIds)[0];
                        enquiryId = response?.data?.enquiryIds[key];
                    }

                    props.setEnableDoorDash(response.data.enabledDoorDash || false)
                    let state = {
                        currentState: ENQUIRY_STATE.LIVE_TOUR_REVIEW,
                        nextState: ENQUIRY_STATE.LIVE_TOUR_SHARE,
                        data: { enquiryId: enquiryId },
                    };
                    //const countEnquiries = document.getElementById('count-enquiries')?.textContent;

                    //if (!isNaN(Number(countEnquiries))) {
                    //    const newCount = Number(countEnquiries) + 1;
                    //    document.getElementById('count-enquiries').textContent = newCount > 0 ? newCount.toString() : '1';
                    //}
                 
                    props.onNext(state);
                    setLoader(false);
                
                } else {
                    sendNewEventGa4SubmitFail()
                    setErrorMessage(response?.data?.errors[0]);
                    setLoader(false);
                   
                }
            })
            .catch((err) => {
                sendNewEventGa4SubmitFail()
                setLoader(false);
                setErrorMessage(err.message);
                console.log(err);
            })
    };

    /* 
      --------------------
        Helper function
      ---------------------
      */
    const enrichForEnquirySave = (token:string) => {
        const updatedChildrenDetails =
            enquiryDetail.liveTourAvailData?.childrenDetails.map((child) => ({
                ...child,
                selectedCareDays: child.selectedCareDays.map(Number),
            }));
        return {
            centreUserIdHash: data.enquiryCentreDetailsList[0]?.centreUserIdHash,
            enquiryType: 4,
            recaptchaResult: token,
            parentFullName: `${profile.firstName} ${profile.lastName}`,
            email: profile.email,
            contactNumber: profile.phone,
            bestTimeToCall: "Anytime",
            saveDetails: profile.isSaveInfoChecked,
            enquiryDetails: null,
            selectedVisitDays: [],
            childrenDetails: updatedChildrenDetails,
            additionalQuestions: [],
            liveTourBookingSlot: enquiryDetail?.liveTourAvailData?.slot,
            token: data.token,
            includeRecommendCentresHtml: true,
            includeUpsellCentresHtml: true,
            centrePostcode: data.enquiryCentreDetailsList[0]?.centrePostcode,
            centreSuburbHashId: data.enquiryCentreDetailsList[0]?.centreSuburbIdHash,
            hasVisitedBrandedHub: hasVisitedBrandedHub,

        };
    };

    return (
        <div className="enquire-form-side relative flex w-full flex-col items-center pt-5 lg:max-w-320 lg:pt-0" style={{
            paddingBottom: window.CurrentUserType !== null ? '79px' : '40px'
        }}>
            <div className="step-meter bg-gray rounded-25 relative">
                <span className="bg-gradient-blue rounded-25 absolute left-0 top-0 h-full w-full"></span>
            </div>
            <div
                className="relative flex h-full w-full flex-col justify-between overflow-y-auto"
                style={{ height: "350px" }}
            >
                <div className="relative flex flex-col justify-between gap-4 pb-8">
                    <h3
                        className="text-black-1 leading-19p text-lg font-bold"
                        style={{ maxWidth: "240px" }}
                    >
                        Review Live Tour Booking
                    </h3>
                    <span className="text-sm text-red-500"> {errorMessage}</span>
               
                    <div className="center-detail-card flex flex-col gap-2">
                        <div className="flex items-center gap-3">
                            <Icon
                                icon="ci:calendar-days"
                                className="text-berry text-sm"
                            ></Icon>
                            <span className="text-black-2 text-xs">
                                {dayName}, {liveTourAvailData.tourDate}
                            </span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Icon
                                icon="mdi:clock-outline"
                                className="text-berry text-sm"
                            ></Icon>
                            <span className="text-black-2 text-xs">
                                {liveTourAvailData.tourTime}
                            </span>
                        </div>
                        <div className="flex items-center gap-3">
                            <Icon
                                icon="material-symbols:location-on-outline"
                                className="text-berry text-sm"
                            ></Icon>
                            <span className="text-black-2 text-xs">
                                {data.enquiryCentreDetailsList[0]?.centreAddress},{" "}
                                {data.enquiryCentreDetailsList[0]?.centreSuburb}{" "}
                                {data.enquiryCentreDetailsList[0]?.centreState}{" "}
                                {data.enquiryCentreDetailsList[0]?.centrePostcode}
                            </span>
                        </div>
                    </div>

                    <div style={{ maxWidth: "240px" }}>
                        <h3 className="text-black-1 text-sm font-bold">Contact details</h3>
                        <div className="mt-3 flex flex-col gap-1.5">
                            <div className="grid-cols-3 grid">
                                <span className="col-span-1 text-black-1 text-xs font-semibold">
                                    Name
                                </span>
                                <span className="col-span-2 text-black-2 text-xs">
                                    {profile.firstName + " " + profile.lastName}
                                </span>
                            </div>
                            <div className="grid-cols-3 grid">
                                <span className="col-span-1 text-black-1 text-xs font-semibold">
                                    Email
                                </span>
                                <span className="col-span-2 text-black-2 text-xs">
                                    {profile.email}
                                </span>
                            </div>
                            <div className="grid-cols-3 grid">
                                <span className="col-span-1 text-black-1 text-xs font-semibold">
                                    Number
                                </span>
                                <span className="col-span-2 text-black-2 text-xs">
                                    {profile.phone}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="captcha-container bg-light-200 flex items-center gap-3">
                        <div className="recaptcha" data-sitekey="@CareForKidsWebConfiguration.Current.GoogleRecaptchaSiteKey"></div>
                    </div> */}
                </div>
            </div>
            <div className="bottom-0 flex w-full flex-col gap-2">
                <button
                    className={`enquire-next-btn bg-rose hover:bg-darkrose flex items-center justify-center font-semibold text-base text-white`}
                    onClick={onSubmit}
                    style={{ marginTop: 0 }}
              
                >
                    {loader ?
                        <>
                            <img src="/img/navy-spinner.svg" alt="Spinner blue dots circle" height="28" width="28" style={{
                                mixBlendMode: 'multiply'
                            }} />
                            <span>Processing...</span>
                        </> : "Submit"}
                </button>
            </div>
        </div>
    );
};

export default EnquiryLiveTourReviewComponent;

import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";
import axios from "axios";

import { env } from "../../../environment";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface IEnquiryReviewComponent {
    [key: string]: any;
}

export const EnquiryReviewComponent = (props: IEnquiryReviewComponent) => {
    const { centreUserIdHash, url, enquiryDetail, centreBrandHashId } = props;
    const { about, profile, comments, createAccount, liveTourAvailData, childrenDetails } =
        enquiryDetail;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [formSubmitCompleted, setFormSubmitCompleted] = React.useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

   


    const getAllIdsFromCookies = (): string[] => {
        const cookies = document.cookie.split('; ');
        const ids: string[] = [];

        cookies.forEach(cookie => {
            if (cookie.startsWith('brandedHash_')) {
                const id = cookie.split('=')[0].split('_')[1]; // Extract ID from cookie name
                ids.push(id);
            }
        });

        return ids;
    };
    const hasVisitedBrandedHub = (getAllIdsFromCookies().includes('g8') && props.centreGroupId === 1) || getAllIdsFromCookies().includes(props.centreBrandHashId)
    // console.log(props);
    /* 
      --------------------
        Handlers function
      ---------------------
      */
    const onSubmit = async () => {
        // API CALL
 
        if (!executeRecaptcha) {
            setErrorMessage("reCAPTCHA not available yet");
            props.sendEventGa4('review', 'submit-fail')
            return;
        }
        setLoader(true);
        const token = await executeRecaptcha("api_call");

        let payload = enrichForEnquirySave(token);

        let headers = {
            "Content-Type": "application/json",
        };

        const json = JSON.stringify(payload);
        axios
            .post(url, json, { headers: headers })
            .then((response: any) => {
                if (response.data.isSuccess) {
                    let enquiryId = 0;
                    if (response?.data?.enquiryIds != null) {
                        var key = Object.keys(response?.data?.enquiryIds)[0];
                        enquiryId = response?.data?.enquiryIds[key];
                    }

                    props.setEnableDoorDash(response.data.enabledDoorDash || false)
                    let state = {
                        currentState: ENQUIRY_STATE.REVIEW,
                        nextState: ENQUIRY_STATE.SHARE,
                        data: { enquiryId: enquiryId },
                    };
                    /*    props.enabledDoorDash(response?.data?.enabledDoorDash || false)*/
                    //const countEnquiries = document.getElementById('count-enquiries')?.textContent;

                    //if (!isNaN(Number(countEnquiries))) {
                    //    const newCount = Number(countEnquiries) + 1;
                    //    document.getElementById('count-enquiries').textContent = newCount > 0 ? newCount.toString() : '1';
                    //}

                    props.onNext(state);
                    setFormSubmitCompleted(true);
                    setLoader(false);
                } else {
             
                    setErrorMessage(response?.data?.errors[0]);
                    setLoader(false);
                    setFormSubmitCompleted(true);
                }
            })
            .catch((err) => {
                setLoader(false);
                setErrorMessage(err.message);
                console.log(err);
            })

    };

    /* 
      --------------------
        Helper function
      ---------------------
      */
    const enrichForEnquirySave = (token:string) => {
        let additionalQuestionsPayload: number[] = [];
        let enquiryType = 1;
        about.map((x) => {
            if (x.label == "Request a tour") {
                enquiryType = x.id;
            }
            if (x.label == "Fees") {
                additionalQuestionsPayload.push(0);
            }
            if (x.label == "Availability") {
                additionalQuestionsPayload.push(1);
            }
            if (x.label == "Program") {
                additionalQuestionsPayload.push(2);
            }
        });

        const updatedChildrenDetails =
            enquiryDetail.liveTourAvailData?.childrenDetails.map((child) => ({
                ...child,
                selectedCareDays: child.selectedCareDays.map(Number),
            }));

        return {
            enquiryType: enquiryType,
            recaptchaResult: token,
            parentFullName: `${profile.firstName.trim()} ${profile.lastName.trim()}`,
            parentFirstName: profile.firstName.trim(),
            parentLastName: profile.lastName.trim(),
            email: profile.email,
            contactNumber: profile.phone,
            bestTimeToCall: profile.timeToCall ? profile.timeToCall : "Anytime",
            suburb: createAccount.suburbId,
            password: createAccount.password,
            confirmPassword: createAccount.confirmPassword,
            enquiryDetails: comments || liveTourAvailData.comments,
            centreUserIdHash: centreUserIdHash,
            saveDetails: profile.isSaveInfoChecked,
            additionalQuestions: additionalQuestionsPayload,
            childrenDetails: updatedChildrenDetails,
            hasVisitedBrandedHub: hasVisitedBrandedHub,

        };
    };

    return (
        <div className="enquire-form-side relative flex w-full flex-col items-center lg:max-w-320">
            <div className="step-meter bg-gray rounded-25 relative">
                <span className="bg-gradient-blue rounded-25 absolute left-0 top-0 h-full w-full"></span>
            </div>
            <div
                className="relative flex h-full w-full flex-col justify-between overflow-y-auto"
                style={{ height: "350px" }}
            >
                <div className="relative flex flex-col justify-between gap-4 pb-8">
                    <h3
                        className="text-black-1 text-lg font-bold"
                        style={{ maxWidth: "240px" }}
                    >
                        Review
                    </h3>
                    <span className="text-sm text-red-500"> {errorMessage}</span>
               
                    <div style={{ maxWidth: "240px" }}>
                        <h3 className="text-black-1 text-sm font-bold">Contact details</h3>
                        <div className="mt-3 flex flex-col gap-1.5">
                            <div className="grid-cols-3 grid">
                                <span className="col-span-1 text-black-1 text-xs font-semibold">
                                    Name
                                </span>
                                <span className="col-span-2 text-black-2 text-xs">
                                    {profile.firstName} {profile.lastName}
                                </span>
                            </div>
                            <div className="grid-cols-3 grid">
                                <span className="col-span-1 text-black-1 text-xs font-semibold">
                                    Email
                                </span>
                                <span className="col-span-2 text-black-2 text-xs">
                                    {profile.email}
                                </span>
                            </div>
                            <div className="grid-cols-3 grid">
                                <span className="col-span-1 text-black-1 text-xs font-semibold">
                                    Number
                                </span>
                                <span className="col-span-2 text-black-2 text-xs">
                                    {profile.phone}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/*<div style={{ maxWidth: "240px" }}>*/}
                    {/*    <h3 className="text-black-1 text-sm font-bold">Your child’s details</h3>*/}
                    {/*    <div className="mt-3 flex flex-col gap-1.5">*/}
                    {/*        <div className="grid-cols-3 grid">*/}
                    {/*            <span className="col-span-1 text-black-1 text-xs font-semibold">*/}
                    {/*                Name*/}
                    {/*            </span>*/}
                    {/*            <span className="col-span-2 text-black-2 text-xs">*/}
                    {/*                Blake*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*        <div className="grid-cols-3 grid">*/}
                    {/*            <span className="col-span-1 text-black-1 text-xs font-semibold">*/}
                    {/*                Birthday*/}
                    {/*            </span>*/}
                    {/*            <span className="col-span-2 text-black-2 text-xs">*/}
                    {/*                12/02/2019 (5 Year old)*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*        <div className="grid-cols-3 grid">*/}
                    {/*            <span className="col-span-1 text-black-1 text-xs font-semibold">*/}
                    {/*                Care days*/}
                    {/*            </span>*/}
                    {/*            <span className="col-span-2 text-black-2 text-xs">*/}
                    {/*                Mon, Tue, Wed*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    
                    <div>
                        <h3 className="text-black-1 text-sm font-bold">Enquiry details</h3>
                        <div className="center-detail-card flex flex-col gap-2 px-3">
                            <ul>
                                {about.map((item) => {
                                    return (
                                        <>
                                            <li key={item.value}>{item.label}</li>
                                        </>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    {comments && comments.trim() != "" && (
                        <div className="mb-3">
                            <h3 className="text-black-1 text-sm font-bold">Comments</h3>
                            <div className="center-detail-card flex flex-col gap-2 px-3">
                                <p className="text-sm font-medium">{comments}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="bottom-0 flex w-full flex-col gap-2">
                <button
                    className={`enquire-next-btn bg-rose hover:bg-darkrose flex items-center justify-center font-semibold text-base text-white`}
                    onClick={onSubmit}
                    style={{ marginTop: 0 }}
               
                >
                    {loader ?
                        <>
                            <img src="/img/navy-spinner.svg" alt="Spinner blue dots circle" height="28" width="28" style={{
                                mixBlendMode: 'multiply'
                            }} />
                            <span>Processing...</span>
                        </> : "Submit"}
                </button>
            </div>
        </div>
    );
};

export default EnquiryReviewComponent;
